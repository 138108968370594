<template>
  <div v-if="items && items.length > 0">
    <VanTag
      class="xa-item__tag"
      v-for="(item, index) in items"
      :key="index"
      :plain="plain"
      v-bind="item"
      >{{ item.title }}</VanTag
    >
  </div>
</template>
<script>
import { Tag } from 'vant'
export default {
  components: {
    VanTag: Tag
  },
  props: {
    plain: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array
    }
  }
}
</script>
<style lang="scss" scoped>
.xa-item__tag {
  & + & {
    margin-left: 4px;
  }
}
</style>
