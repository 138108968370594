<template>
  <div class="xa-cell__box base-info__item xa-bg-white" @click="onClick">
    <div class="xa-cell">
      <MarkdownLoader class="xa-flex xa-txt-16" :value="title" />
      <ItemTag :items="status" />
    </div>
    <MarkdownLoader :value="subTitle" />
    <EnsurePayInfo v-if="items && items.length" v-bind="$props" style="padding:0"/>
    <ItemTag :plain="true" :items="tags" />
    <div class="xa-cell" v-if="foot || time">
      <div class="xa-flex xa-txt-14">{{ foot }}</div>
      <div class="xa-txt-13 xa-txt-secondary">{{ time }}</div>
    </div>
  </div>
</template>
<script>
import ItemTag from '@/components/diylist/ItemTag'
import EnsurePayInfo from '@/components/EnsurePayInfo'
import MarkdownLoader from '@/components/MarkdownLoader'
export default {
  name: 'BaseInfoItem',
  components: {
    ItemTag,
    EnsurePayInfo,
    MarkdownLoader
  },
  props: {
    title: String,
    subTitle: String,
    foot: String,
    time: String,
    href: String,
    status: {
      type: Array,
      default() {
        return []
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    tags: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('action', {
        href: this.href
      })
    }
  }
}
</script>
<style lang="scss">
.base-info__item {
  > div {
    margin: 4px 0;
  }
  .ensure-pay-info {
    padding: 0;
  }
}
</style>
