<template>
  <section>
    <TemplateDiyList :coms="coms" v-on="{ action: onAction }" />
  </section>
</template>
<script>
import diyList from '@/mixins/diyList'
let curPath = ''
let coms = []
export default {
  mixins: [diyList],
  config: {
    fetchDataFn: 'initView',
    watchRoute: true
  },
  methods: {
    onAction(btn) {
      if (btn.href || btn.ajax) this.$_handAction(btn)
    },
    async initView() {
      const { url, data } = this.$_extractRequestInfoFormRoute()
      const result = await this.$_request({
        url: url || '/mock/diy-list',
        data
      })
      let { body: coms } = result
      this.coms = this.$_convertComs(coms)
      return result
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.fullPath === curPath) {
        vm.coms = coms || []
      }
      curPath = ''
    })
  },
  beforeRouteLeave(to, from, next) {
    curPath = from.fullPath
    coms = JSON.parse(JSON.stringify(this.coms))
    next()
  }
}
</script>
