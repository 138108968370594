const ComsMap = {
  eventinfo: 'EventInfoItem',
  baseinfo: 'BaseInfoItem',
  more: 'MoreItem',
  empty: 'EmptyItem',
} // key 都要用小写
import TemplateDiyList from '@/components/template/TemplateDiyList'
import basePage from '@/mixins/basePage'
export default {
  mixins: [basePage],
  components: {
    TemplateDiyList
  },
  data() {
    return {
      coms: []
    }
  },
  methods: {
    async $_handAction(btn) {
      const { action } = btn
      const result = await this.$_handleCfgAction(btn)
      if (action === 'fetch') {
        let { body: coms } = result
        this.coms.splice(this.coms.length - 1, 1)
        this.coms.push(...this.$_convertComs(coms))
      }
    },
    $_convertComs(coms) {
      return coms.map(item => {
        const comType = item.UiType || item.type || 'baseinfo'
        item.UiType = ComsMap[comType.toLocaleLowerCase()]
        window.console.warn('comType', item.UiType)
        return item
      })
    }
  }
}
