<template>
  <div class="xa-txt-center xa-txt-13 xa-txt-blue xa-cell__box" @click="onClick">
    {{ title }}
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '加载更多'
    },
    api: String,
    params: {
      type: Object,
      default() {}
    }
  },
  methods: {
    onClick() {
      this.$emit('action', {
        action: 'fetch',
        ajax: {
          url: this.api,
          data: this.params
        }
      })
    }
  }
}
</script>
