<template>
  <div>
    <div class="xa-com__space" v-for="(com, index) in coms" :key="index">
      <component :is="com.UiType" v-bind="com" v-on="{ action: onAction }" />
    </div>
  </div>
</template>
<script>
import BaseInfoItem from '@/components/diylist/BaseInfoItem'
import EventInfoItem from '@/components/diylist/EventInfoItem'
import MoreItem from '@/components/diylist/MoreItem'
import EmptyItem from '@/components/diylist/EmptyItem'
export default {
  components: {
    BaseInfoItem,
    EventInfoItem,
    MoreItem,
    EmptyItem
  },
  props: {
    coms: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    onAction(btn) {
      this.$emit('action', btn)
    }
  }
}
</script>
