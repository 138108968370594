<template>
  <div class="xa-cell__box eventInfo__box">
    <MarkdownLoader :value="content" />
  </div>
</template>
<script>
import MarkdownLoader from '@/components/MarkdownLoader'
export default {
  name: 'EventInfoItem',
  components: {
    MarkdownLoader
  },
  props: {
    content: String
  }
}
</script>
<style lang="scss" scoped>
.eventInfo__box {
  background-color: #fff5c2;
}
</style>
